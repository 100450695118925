import styles from './navbar.module.scss';
import { Link } from "react-router-dom";

const Navbar = () => {

  return (
    <div className={styles.navbar}>
      <Link to="/login" style={{ textDecoration: "none", marginLeft: 30, marginRight: 10 }}>
        <div>
          <img src='/img/lithuaniaLogo.png' alt="LTU-INT" style={{width: 70}} />
        </div>
      </Link>
      <div className={styles.wrapper}>

        <div className={styles.search}>
          
          <Link to="/" style={{ textDecoration: "none" }}>
            <div style={{borderStyle: 'solid', borderWidth: 1, borderRadius: 15, color: 'white', borderColor: 'white', padding: 8, paddingLeft: 12, paddingRight: 12, fontSize: 20}}>
              LTU-OSINT
            </div>
          </Link>
        </div>

        <div className={styles.items}>
          
          <div className={styles.item}>
            {/* <DarkModeOutlinedIcon
              className="icon"
              onClick={() => dispatch({ type: "TOGGLE" })}
            /> */}
          </div>

          {/* <div className="item">
            <ListOutlinedIcon className="icon" />
          </div> */}

          <Link to="/myprofile" style={{ textDecoration: "none" }}>
            <div className={styles.item}>
              {/* <div className="avatar-name-surname">Tomas Vanagas</div> */}
              <img src="/img/avatar.png" alt="" className={styles.avatar} />
            </div>
          </Link>

        </div>
      </div>
    </div>
  );
};

export default Navbar;
