import { BrowserRouter as Router, Routes, Route, useLocation, Outlet } from "react-router-dom";
import axios from "axios";

// Common
import Login from './pages/Login/Login';

import MapTest from "./pages/MapTest/MapTest";

import Home from './pages/Home/Home';
import Search from "./pages/Search/Search";
import Stats from "./pages/Stats/Stats";

axios.defaults.withCredentials = true;


export default function App() {
  // let location = useLocation();

  return (
    <Router>
      <Routes >
        <Route>

          <Route exact path="/login" element={ <Login/> }/>

          <Route exact path="/" element={ <Home/> }/>
          <Route exact path="/map/" element={ <MapTest/> }/>
          <Route exact path="/search/" element={ <Search/> }/>
          <Route exact path="/stats/" element={ <Stats/> }/>
        
          


          {/* <Route path="/admin/students">
            <Route index element={<StudentsListPage />} />
            <Route exact path="/admin/students/:studentID" element={<StudentInformation />} />
          </Route>

          <Route path="/admin/questions">
            <Route index element={<QuestionsPage />} />
          </Route> */}


        </Route>
      </Routes>    
    </Router>
  );
}


