import React, { useEffect, useState } from "react";

import axios from "axios";
import BouncingDotsLoader from './components/BouncingDotsLoader/BouncingDotsLoader';
import Particles from './components/Particles/Particles';

import CryptoJS from 'crypto-js';

import { Button, Box, Stack, FormControl, TextField } from "@mui/material";
import Typography from '@mui/joy/Typography';
import loginLogo from './madeInLT.jpg';


const styles = {
  loginPage: {
    backgroundImage: "linear-gradient(to bottom right, #7b4397 , #dc2430)",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: -2,
    loginForm: {
      maxWidth: 330,
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      background: "white",
      padding: 20,
      marginTop: "10%",
      borderRadius: 15,
    },
    errorBox: {
      fontSize: '12px', 
      color: 'red', 
      textAlign: 'center', 
      whiteSpace: 'pre-wrap'
    },
    footer: {
      height: 100,
      width: "100%",
      position: "absolute",
      bottom: 0,
      alignItems: "center",
      justifyContent: "center",
    },
    copyright: {
      color: "#fff",
      lineHeight: "10px",
      fontSize: "0.7em",
      marginTop: 50,
      textAlign: "center",
    },
  },
};


export default function Login() {

  const [loggingIn, setLoggingIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorBoxText, setErrorBoxText] = useState("");


  useEffect(() => {
    axios.get(window.location.protocol+"//"+process.env.REACT_APP_BACKEND+"/logout");
  }, []);


  const handleSubmit = () => {
    const loginUser = async () =>  {
      var hash = password;
      for (let i = 0; i < 20000; i++) {
        hash = CryptoJS.SHA1(hash).toString(CryptoJS.enc.Hex)
      }      

      await axios.post(window.location.protocol+"//"+process.env.REACT_APP_BACKEND+"/api/login", { email: email, password: hash }).then((response) => {
        if(response.data === "OK"){
          window.location.href="/"
        }
        else{
          setLoggingIn(false);
          setErrorBoxText(response.data);
        }
      });


      // window.location.href="/map";
    }
    loginUser();
    setLoggingIn(true);
  }




  return (
    <Box style={styles.loginPage}>
      <form  style={styles.loginPage.loginForm}>
        
        <img alt="" src={loginLogo} width="330" height="192"/>
        
        <Box style={{textAlign: "center", marginTop: 10}}>
          {/* <Typography component="h1" level="inherit" fontSize="1.1em" mb="0.25em">
            LTU-OSINT
          </Typography> */}
        </Box>

        <Stack spacing={2} style={{marginTop: 10, marginBottom: 60}} >
          <FormControl size="lg" color="primary">
            <TextField required variant="standard" label="Prisijungimo Vardas" onChange={ (e) => {setEmail(e.currentTarget.value)}}/>
          </FormControl>

          <FormControl size="lg" color="primary">
            <TextField required variant="standard" type="password" label="Slaptažodis" onChange={ (e) => {setPassword(e.currentTarget.value)}}/>
          </FormControl>
        </Stack>

        
        <Box style={styles.loginPage.errorBox}>
          {errorBoxText}
        </Box>
        
        {loggingIn? 
          <Button onClick={handleSubmit} disabled={true} style={{backgroundColor: 'grey', color: 'white', pointerEvents: 'none', }}>
            PALAUKITE <BouncingDotsLoader/>
          </Button>
        :
          <Button onClick={handleSubmit} style={{backgroundColor: 'rgb(123, 0, 63)', color: 'white'}} >
            PRISIJUNGTI
          </Button>
        }
          
      </form>

      
      <Particles/>

      <Box style={styles.loginPage.footer}>
        <Box style={styles.loginPage.copyright}>
          Copyright © | All Rights Reserved | Tomas Vanagas
        </Box>
      </Box>
    </Box>
  );
}


