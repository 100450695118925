import React, { useEffect, useRef } from 'react';

function CustomControl({ map }) {
  const controlContainer = useRef(null);

  useEffect(() => {
    if (map && controlContainer.current) {
      // Implement the custom control interface required by Mapbox GL JS
      const control = {
        onAdd: function() {
          return controlContainer.current;
        },
        onRemove: function() {},
        getDefaultPosition: function() {
          return 'top-right';
        },
      };

      // Add the control to the map
      map.addControl(control);

      return () => {
        if (map && map.removeControl) {
          map.removeControl(control);
        }
      };
    }
  }, [map]);

  return (
    <div ref={controlContainer} className="custom-control">
      <button onClick={() => alert('Button clicked!')}>Click Me!</button>
    </div>
  );
}

export default CustomControl;
