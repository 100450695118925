import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import Widget from "../../components/Widget/Widget";

import React, { useState, useEffect } from "react";
import axios from "axios";


import {TextField, InputAdornment, FormControl} from '@mui/material';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';




const SearchComponent = ({setSearchQuery}) => {
  const [searchFieldText, setSearchFieldText] = useState("");


  const handleChange = (e) => {
    setSearchFieldText(e.target.value)
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  const handleClick = () => {
    setSearchQuery(searchFieldText);
  };


  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FormControl style={{display: 'flex', flexDirection: 'row'}}>
        <TextField
          size="small"
          variant="outlined"
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          placeholder="Ieškoti..."
          style={{
            width: 500,
            margin: 20
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={handleClick}
                style={{margin: 0, padding: 0}}
              >
                <Button 
                  onClick={handleClick}
                  style={{
                    minWidth: '30px', // Reduces the width
                    padding: '6px', // Adjust padding as needed
                    margin: '0', // Removes any default margin
                  }}
                >
                  <SearchIcon />
                </Button>
              </InputAdornment>
            )
          }}
        />
      </FormControl>
    </div>
  );
}


function ResultsTable({ searchResults }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <TableContainer component={Paper} sx={{ width: '100%', marginLeft: '5vw', marginRight: '5vw' }}>
        <Table  aria-label="simple table">
          <TableHead>
            <TableRow style={{ backgroundColor: 'lightgrey' }}>
              <TableCell align="left"   style={{ fontWeight: 'bold' }}>Šaltinis</TableCell>
              <TableCell align="left"   style={{ fontWeight: 'bold' }}>MAC Adresas</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>SSID</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>Koordinatės</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>Apskritis</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>Miestas</TableCell>
              <TableCell align="right"  style={{ fontWeight: 'bold' }}>Adresas</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchResults.map((row) => (
              <TableRow
                // key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">{row.datasource}</TableCell>
                <TableCell align="left">{row.mac}</TableCell>
                <TableCell align="center">{row.ssid}</TableCell>
                <TableCell align="center"><a href={window.location.protocol+"//"+process.env.REACT_APP_BACKEND+`/map?lat=${row.lat}&long=${row.long}&zoom=18`}>{`${row.lat} ${row.long}`}</a></TableCell>
                <TableCell align="center">{row.region}</TableCell>
                <TableCell align="center">{row.city}</TableCell>
                <TableCell align="right">{row.address}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}





const Search = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);


  useEffect(() => {
    async function querySearchData(searchQuery) {
      const apiUrl = window.location.protocol+"//"+process.env.REACT_APP_BACKEND+`/api/searchQuery?searchQuery=${searchQuery}`;

      try {
          const response = await fetch(apiUrl);
          if (!response.ok) 
              throw new Error(`HTTP error! Status: ${response.status}`);
          const data = await response.json();
          setSearchResults(data);
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = '/login';
        }
      }
    }

    querySearchData(searchQuery);
  }, [searchQuery]);


  


  return (
    <div>
      <Navbar />
      <div style={{display: 'flex', flexDirection: 'row'}}> 
        
        <Sidebar/>
        <div
          style={{width: "100%", height: "calc(100vh - 105px)"}}
        >
          <div style={{
            width: '100%',
            fontSize: '24px',
            color: 'gray',
            margin: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            WiFi Stotelių paieška
          </div>
          <SearchComponent setSearchQuery={setSearchQuery}/>

          <div style={{ height: "calc(100vh - 300px)", overflowY: 'auto' }}>
            {searchResults.length !== 0 ?
              <ResultsTable searchResults={searchResults}/>
            :
              <div style={{margin: 50}}>Rezultatų Nėra</div>
            }
            
          </div>

        </div>
      </div>
      <div style={{background: 'rgb(123, 0, 63)', height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', fontSize: "0.7em"}}> 
        Copyright © | All Rights Reserved | Tomas Vanagas
      </div>
    </div>
  );
};

export default Search;