import * as React from 'react';
import axios from 'axios';
import {useState, useEffect, useMemo} from 'react';
import Map, { NavigationControl, Source, Layer, Popup, Marker } from 'react-map-gl';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from "../../components/Sidebar/Sidebar";
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import './MapTest.css';
//import {heatmapLayer} from './map-style.ts';
import { useRef } from 'react';
import CustomControl from './CustomControl';
import IOSSwitch from '../../components/other/IOSSwitch/IOSSwitch';
import { FormGroup, FormControlLabel } from '@mui/material';




function WigleAppUpload() {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Prašome pasirinkti failą siuntimui.');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post(window.location.protocol+"//"+process.env.REACT_APP_BACKEND+'/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(response.data);
      alert('Failas išsiųstas sėkmingai');
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Nepavyko išsiųsti failo');
    }
  };

  return (
    <div 
      style={{ 
        border: '1px solid grey', 
        borderRadius: 5, 
        marginLeft: 50, 
        padding: 5,
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <div style={{marginRight: 20}}><b><u>WigleAPP</u></b> Bazės siuntimas: </div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Siųsti</button>
    </div>
  );
}







function App() {
  const [earthquakes, setEarthQuakes] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);

  const [showWiglePoints, setShowWiglePoints] = useState(true);
  const [showWigleAppPoints, setShowWigleAppPoints] = useState(true);



  // Parse URL query parameters
  const getQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    const lat = parseFloat(params.get('lat'));
    const long = parseFloat(params.get('long'));
    const zoom = parseFloat(params.get('zoom'));
    return { lat, long, zoom };
  };



  // On Map Load
  useEffect(() => {


    // Function to query data based on map bounds
    const queryDataBasedOnMap = () => {
      if (mapInstance) {
        const bounds = mapInstance.getBounds();
        const lowerLeftCorner = { lng: bounds.getWest(), lat: bounds.getSouth() };
        const upperRightCorner = { lng: bounds.getEast(), lat: bounds.getNorth() };
        queryMapData(lowerLeftCorner, upperRightCorner, mapInstance.getZoom());
      }
    };

    // Update map view based on URL parameters
    const { lat, long, zoom } = getQueryParams();
    if (!isNaN(lat) && !isNaN(long)) {
      setViewState({
        ...viewState,
        latitude: lat,
        longitude: long,
        zoom: zoom
      });
    }

    // Query data when mapInstance is available
    if (mapInstance) {
      queryDataBasedOnMap();
    }

  }, [mapInstance]);



  // Function to query data based on map bounds and zoom level
  async function queryMapData(lowerLeftCorner, upperRightCorner, zoomLevel) {
    const apiUrl = window.location.protocol+"//"+process.env.REACT_APP_BACKEND+`/api/points.geojson?minLng=${lowerLeftCorner.lng}&minLat=${lowerLeftCorner.lat}&maxLng=${upperRightCorner.lng}&maxLat=${upperRightCorner.lat}&zoom=${zoomLevel}`;

    try {
      const response = await fetch(apiUrl);

      if (response.status === 401) {
        window.location.href = '/login';
        return;
      }


      if (!response.ok) 
          throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      setEarthQuakes(data);
    } catch (error) {
      if (error.response.status === 401) {
        window.location.href = '/login';
      }
    }
  }



  const data = useMemo(() => {
    return earthquakes;
  });

  


  const [viewState, setViewState] = React.useState({
    longitude: 24.19255612981908,
    latitude: 55.12,
    zoom: 7
  });


  
  const [popupInfo, setPopupInfo] = useState(null);
  function handleMapClick(e) {
    const lngLat = e.lngLat || [];
    const longitude = lngLat.lng;
    const latitude = lngLat.lat;
    setPopupInfo( { longitude: lngLat.lng, latitude: lngLat.lat });
  }





  // A utility function to delay the execution of another function
  function delayFunctionExecution(func, waitInMillis) {
    let timeoutId;
    return function(...args) {
        const context = this;
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func.apply(context, args), waitInMillis);
    };
  }





  const logMapBoundsRef = useRef(
    delayFunctionExecution(async function(evt) {
      const mapInstance = evt.target;

      const visibleBounds = mapInstance.getBounds();
      const lowerLeftCorner = visibleBounds.getSouthWest();
      const upperRightCorner = visibleBounds.getNorthEast();

      const currentZoomLevel = mapInstance.getZoom();

      await queryMapData(lowerLeftCorner, upperRightCorner, currentZoomLevel);
    }, 50)  // Increased debounce time
  );





  function handleMapMove(evt) {
    setViewState(evt.viewState);
    logMapBoundsRef.current(evt);
  }

  


  return (
    <div>
      <Navbar/>

      <div style={{display: 'flex', flexDirection: 'row'}}>
        <Sidebar/>
        <div style={{flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <div style={{ height: 50, display: 'flex', alignItems: 'center' }}>
            <FormGroup
              sx={{
                marginLeft: '20px',
                display: 'inline'
              }}
            >
              <FormControlLabel
                sx={{margin: 'auto', paddingLeft: 10 }}
                label="Duomenys: " 
                control={<></>}
              />

              <FormControlLabel
                sx={{margin: 'auto', paddingLeft: 10 }}
                control={
                  <IOSSwitch
                    defaultChecked={true}
                    sx={{
                      marginRight: '10px'
                    }}
                    onChange={(e) => setShowWiglePoints(e.target.checked)}
                  />}
                label="Wigle.Net" 
              />

              <FormControlLabel
                sx={{margin: 'auto', paddingLeft: '50px' }}
                control={
                  <IOSSwitch
                    defaultChecked={true}
                    sx={{
                      marginRight: '10px'
                    }}
                    onChange={(e) => setShowWigleAppPoints(e.target.checked)}
                  />}
                label="Wigle APP"
              />
            </FormGroup>
            <WigleAppUpload/>
          </div>
          <Map 
            mapLib={maplibregl}
            {...viewState} 
            onMove={handleMapMove}

            style={{width: "100%", height: "calc(100vh - 127px)"}}
            // mapStyle="http://localhost:8000/maps/streets/style.json"
            // mapStyle="http://localhost:8000/maps/satellite/style.json"
            // mapStyle="http://localhost:8000/maps/openstreetmap/style.json"
            // mapStyle="http://localhost:8000/maps/terrain-quantized-mesh-v2/style.json"
            mapStyle={window.location.protocol+"//"+process.env.REACT_APP_BACKEND+"/api/maps/topo-v2/style.json"}
          
            onClick={handleMapClick}
            ref={(ref) => ref && setMapInstance(ref.getMap())}
          >
            
            <NavigationControl position="top-left" />
            
            {popupInfo && (
              <Popup
                longitude={popupInfo.longitude}
                latitude={popupInfo.latitude}
                onClose={() => setPopupInfo(null)}
              >
                <div>
                  Longitude: {popupInfo.longitude}<br />
                  Latitude: {popupInfo.latitude}
                </div>
              </Popup>
            )}

            {/* <Marker
              longitude={viewport.longitude}
              latitude={viewport.latitude}
              offsetLeft={-20}
              offsetTop={-10}
            >
            <div>You can customize the marker here</div>
            </Marker> */}



            <Source type="geojson" data={data}>
              {/* Aggregated Points */}
              <Layer 
                id="aggregate-point" 
                type="circle"
                paint={{
                  'circle-radius': [
                    'step', 
                    ['zoom'],
                    ['*', ['log10', ['+', ['get', 'radius'], 10]], 10], // default for zooms < 15
                    16, 0 // At zoom 15 or above, set radius to 0
                  ],
                  'circle-color': '#007cbf',
                  'circle-opacity': [
                    'step',
                    ['zoom'],
                    1, // default for zooms < 15
                    15, 0 // At zoom 15 or above, set opacity to 0
                  ]
                }}
              />
              <Layer
                id="aggregate-label"
                type="symbol"
                layout={{
                  'text-field': '{count}',  
                  'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                  'text-size': 12,
                  'text-offset': [0, 0]
                }}
              />

              {/* WiGLE Points Layer */}
              <Layer 
                id="wigle-point" 
                type="circle"
                filter={['==', ['get', 'isWigle'], 1]}  // Only show WiGLE points
                layout={{ visibility: showWiglePoints ? 'visible' : 'none' }}
                paint={{
                  'circle-radius': [
                    'step', 
                    ['zoom'],
                    0, // default for zooms < 15
                    15, 4 // At zoom 15 or above, set radius to 4
                  ],
                  'circle-color': 'blue',  // Blue color for WiGLE points
                  'circle-opacity': [
                    'step',
                    ['zoom'],
                    0, // default for zooms < 15
                    15, 1 // At zoom 15 or above, set opacity to 1
                  ]
                }}
              />

              {/* WiGLEApp Points Layer */}
              <Layer 
                id="wigleapp-point" 
                type="circle"
                filter={['==', ['get', 'isWigle'], 0]}  // Only show WiGLEApp points
                layout={{ visibility: showWigleAppPoints ? 'visible' : 'none' }}
                paint={{
                  'circle-radius': [
                    'step', 
                    ['zoom'],
                    0, // default for zooms < 15
                    15, 4 // At zoom 15 or above, set radius to 4
                  ],
                  'circle-color': 'red',  // Red color for WiGLEApp points
                  'circle-opacity': [
                    'step',
                    ['zoom'],
                    0, // default for zooms < 15
                    15, 1 // At zoom 15 or above, set opacity to 1
                  ]
                }}
              />
              
              {/* WiGLE Label Layer */}
              <Layer
                id="wigle-label"
                type="symbol"
                filter={['==', ['get', 'isWigle'], 1]}  // Filter for WiGLE points
                layout={{
                  'text-field': '{ssid}',  
                  'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                  'text-size': 12,
                  'text-offset': [0.0, 1.0],
                  'text-allow-overlap': false
                }}
                paint={{
                  'text-opacity': showWiglePoints ? 1 : 0,  // Controlled by showWiglePoints state variable
                }}
              />

              {/* WiGLEApp Label Layer */}
              <Layer
                id="wigleapp-label"
                type="symbol"
                filter={['==', ['get', 'isWigle'], 0]}  // Filter for WiGLEApp points
                layout={{
                  'text-field': '{ssid}',  
                  'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                  'text-size': 12,
                  'text-offset': [0.0, 1.0],
                  'text-allow-overlap': false
                }}
                paint={{
                  'text-opacity': showWigleAppPoints ? 1 : 0,  // Controlled by showWigleAppPoints state variable
                }}
              />

            </Source>

            {/* <CustomControl map={mapInstance}/> */}

          </Map>
        </div>
      </div>
      
    </div>
  );
}

export default App;
