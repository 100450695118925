import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";

import React, { useState, useEffect } from "react";
import axios from "axios";

// Tabs
import { Box, Tab, Tabs } from '@mui/material';


// Tables
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';





function ResultsBySSIDTable({ results }) {
  // Ensure each count is a number and calculate the total count
  // const totalCount = results.reduce((sum, record) => sum + Number(record.Count), 0);
  const totalCount = 660000;


  // Check if totalCount is valid to avoid division by zero
  const isTotalCountValid = totalCount > 0;

  // Sort the results by count, from largest to smallest
  const sortedResults = [...results].sort((a, b) => b.Count - a.Count);

  const tableCellStyles = {
    fontWeight: 'bold',
    fontSize: '30px', // Adjust this value to increase or decrease the font size
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <TableContainer component={Paper} sx={{ width: '100%', marginLeft: '5vw', marginRight: '5vw' }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow style={{ backgroundColor: 'lightgrey' }}>
              <TableCell align="left" style={tableCellStyles}>SSID Pradžia</TableCell>
              <TableCell align="left" style={tableCellStyles}>Kiekis</TableCell>
              <TableCell align="center" style={tableCellStyles}>Procentinė Dalis</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedResults.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left" style={{ fontSize: '20px' }}>{row.SSID}</TableCell>
                <TableCell align="left" style={{ fontSize: '20px' }}>{row.Count}</TableCell>
                <TableCell align="center" style={{ fontSize: '20px' }}>
                  {isTotalCountValid ? ((row.Count / totalCount) * 100).toFixed(2) + '%' : 'N/A'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}








const Stats = () => {
  const [data, setData] = useState([]);


  useEffect(() => {
    async function getData() {
      const apiUrl = window.location.protocol+"//"+process.env.REACT_APP_BACKEND+`/api/stats/ssidstarts`;

      try {
          const response = await fetch(apiUrl);

          if (response.status === 401) {
            window.location.href = '/login';
            return;
          }

          if (!response.ok) 
              throw new Error(`HTTP error! Status: ${response.status}`);
          const returnedData = await response.json();
          setData(returnedData);
      } catch (error) {
          console.error("Failed to fetch data:", error);
      }
    }

    getData();
  }, []);




  return (
    <div>
      <Navbar />
      <div style={{display: 'flex', flexDirection: 'row'}}> 
        
        <Sidebar/>
        <div
          style={{width: "100%", minHeight: "calc(100vh - 105px)"}}
        >
          <div style={{
            width: '100%',
            fontSize: '24px',
            color: 'gray',
            margin: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            WiFi Duomenų Statistika
          </div>

          <div style={{ overflowY: 'auto', padding: 40 }}>
            <ResultsBySSIDTable results={data}/>
          </div>

        </div>
      </div>
      <div style={{background: 'rgb(123, 0, 63)', height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', fontSize: "0.7em"}}> 
        Copyright © | All Rights Reserved | Tomas Vanagas
      </div>
    </div>
  );
};

export default Stats;