import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import Widget from "../../components/Widget/Widget";


import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./Home.module.scss";



import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import EngineeringIcon from '@mui/icons-material/Engineering';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import CastForEducationOutlinedIcon from '@mui/icons-material/CastForEducationOutlined';





const Home = () => {

  const [data, setData] = useState([]);


  useEffect(() => {
    async function getData() {
      try {
        const response = await axios.get(window.location.protocol+"//"+process.env.REACT_APP_BACKEND+"/api/home");
        
        if (response.status === 401) {
          window.location.href = '/login';
          return;
        }
        
        
        setData(response.data);
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = '/login';
        }
      }
    }
    // getData();
    window.location.href="/map";
  }, []);


  const getIconFromName = (iconName) => {
    //const iconColor = "rgba(255, 0, 0, 0.2)";
    //const iconColor = "crimson";

    const iconBackground = "rgb(230, 65, 100, 1.0)";
    const iconColor = "white";

    switch (iconName) {
      case 'PersonOutlinedIcon':
        return <PersonOutlinedIcon className={styles.icon} style={{ color: iconColor, backgroundColor: iconBackground }} />;
      case 'QuestionMarkOutlinedIcon':
        return <QuestionMarkOutlinedIcon className={styles.icon} style={{ color: iconColor, backgroundColor: iconBackground }} />;


      case 'CreditCardOutlinedIcon':
        return <CreditCardOutlinedIcon className={styles.icon} style={{ color: iconColor, backgroundColor: iconBackground }} />;
      case 'DirectionsCarOutlinedIcon':
        return <DirectionsCarOutlinedIcon className={styles.icon} style={{ color: iconColor, backgroundColor: iconBackground }} />;
      case 'PeopleOutlinedIcon':
        return <PeopleOutlinedIcon className={styles.icon} style={{ color: iconColor, backgroundColor: iconBackground }} />;
      case 'RecordVoiceOverIcon':
        return <RecordVoiceOverIcon className={styles.icon} style={{ color: iconColor, backgroundColor: iconBackground }} />;
      case 'ElectricBoltIcon':
        return <ElectricBoltIcon className={styles.icon} style={{ color: iconColor, backgroundColor: iconBackground }} />;
      case 'EngineeringIcon':
        return <EngineeringIcon className={styles.icon} style={{ color: iconColor, backgroundColor: iconBackground }} />;
      case 'TerminalOutlinedIcon':
        return <TerminalOutlinedIcon className={styles.icon} style={{ color: iconColor, backgroundColor: iconBackground }} />;
      case 'SchoolOutlinedIcon':
        return <SchoolOutlinedIcon className={styles.icon} style={{ color: iconColor, backgroundColor: iconBackground }} />;
      case 'CastForEducationOutlinedIcon':
        return <CastForEducationOutlinedIcon className={styles.icon} style={{ color: iconColor, backgroundColor: iconBackground }} />;
    }
  }

  

  return (
    <div>
      <Navbar />
      <div style={{display: 'flex', flexDirection: 'row'}}> 
        
        <Sidebar/>

        <div style={{minHeight: 'calc(100vh - 125px)', width: '100%', background: '#EBECEF', display: 'flex', flexDirection: 'column', paddingTop: 20 }}>
          
          <div style={{display: 'flex', padding: 100, gap: 30}}>
            <Widget className={styles.widget} text="Wifi Wigle Taškų" count={data.wiglecount} icon={getIconFromName("PeopleOutlinedIcon")} link="/map"/>
            <Widget className={styles.widget} text="Wifi Wigle APP Taškų" count={data.wigleappcount} icon={getIconFromName("PeopleOutlinedIcon")} link="/map"/>
          </div>
        

        </div>
      </div>
      <div style={{background: 'rgb(123, 0, 63)', height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', fontSize: "0.7em"}}> 
        Copyright © | All Rights Reserved | Tomas Vanagas
      </div>
    </div>
  );
};

export default Home;
