import styles from "./Sidebar.module.scss";
import { useState } from "react";
import { Link } from "react-router-dom";

// Collapse/Expand Sidebar
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';


// Pagrindinis
import DashboardIcon from "@mui/icons-material/Dashboard";

// Sąrašai
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
// import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ComputerOutlinedIcon from '@mui/icons-material/Computer';

// Lankomumas
// import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
// import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';

// Sistema
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";

// Profilis
import ExitToAppIcon from "@mui/icons-material/ExitToApp";





// https://www.makeuseof.com/react-create-collapsible-side-navigation-menu/


const AdminSidebar = () => {
  const [open, setopen] = useState(localStorage.getItem("sidebarOpen") !== "false");

  const toggleOpen = () => {
    var sidebarOpendNewValue = !open;
    setopen(sidebarOpendNewValue)
    localStorage.setItem('sidebarOpen', sidebarOpendNewValue);
  }


  return (
    <div className={styles.sidebar}>
      
      <div className={styles.center}>
        
        <ul>
          <button className={styles.sidebarCollapseBtn} onClick={toggleOpen}>
            {open? <KeyboardDoubleArrowLeftIcon style={{verticalAlign: 'middle'}}/>:<KeyboardDoubleArrowRightIcon style={{verticalAlign: 'middle'}}/>}
          </button>


          {/* ---- */}
          {/* {open? <p className={styles.title}>PAGRINDINIS</p>:<p className={styles.title}>-----</p>}
          <Link to="/" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className={styles.icon} />
              {open? <span>Pradžia</span>:<></>}
            </li>
          </Link> */}



          {/* ---- */}
          {open? <p className={styles.title}>Įrankiai</p>:<p className={styles.title}>-----</p>}
          <Link to="/search" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className={styles.icon} />
              {open? <span>Paieška</span>:<></>}
            </li>
          </Link>

          <Link to="/map" style={{ textDecoration: "none" }}>
            <li>
              <ComputerOutlinedIcon className={styles.icon} />
              {open? <span>Žemėlapis</span>:<></>}
            </li>
          </Link>

          <Link to="/stats" style={{ textDecoration: "none" }}>
            <li>
              <ComputerOutlinedIcon className={styles.icon} />
              {open? <span>Statistika</span>:<></>}
            </li>
          </Link>



          {/* ---- */}
          {open? <p className={styles.title}>SISTEMA</p>:<p className={styles.title}>-----</p>}
          {/* <Link to="/admin" style={{ textDecoration: "none" }}>
            <li>
              <SettingsApplicationsIcon className={styles.icon} />
              {open? <span>Sistema</span>:<></>}
            </li>
          </Link> */}

          <Link to="/login" style={{ textDecoration: "none" }}>
            <li>
              <ExitToAppIcon className={styles.icon} />
              {open? <span>Atsijungti</span>:<></>}
            </li>
          </Link>




        </ul>
      </div>
      
    </div>
  );
};

export default AdminSidebar;
